import React from "react";

const Footer = () => {
  const navigateToTerms = () => {
    window.open("/terms", "_blank");
  };
  const navigateToPrivacyPolicy = () => {
    window.open("/privacy-policy", "_blank");
  };

  return (
    <div className="bg-slate-100">
      <div className="sm:p-10 p-4 grid sm:grid-cols-12 grid-cols-1 items-center text-gray-500">
        <div className="sm:col-span-5 text-sm text-center sm:text-left mb-2 sm:mb-0">
          © 2024 - Tipkit LLC. All Rights Reserved.
        </div>
        <div className="sm:col-span-7 flex flex-col sm:flex-row justify-center sm:justify-end text-center sm:text-right space-y-2 sm:space-y-0 sm:space-x-4">
          <a
            onClick={navigateToPrivacyPolicy}
            className="text-sm hover:text-primary hover:cursor-pointer"
          >
            Privacy Policy
          </a>
          <a
            onClick={navigateToTerms}
            className="text-sm hover:text-primary hover:cursor-pointer"
          >
            Terms of Use
          </a>
          <a
            className="text-sm hover:text-primary hover:cursor-pointer"
            href="mailto:hello@tipkit.co"
          >
            Support
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
